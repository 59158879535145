import Swiper from "swiper";

export default {
    init() {
        // JavaScript to be fired on the home page
        $("body").data("loading", "loading!");


    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
