import easydropdown from 'easydropdown'
import Swiper from 'swiper'

export default {
    init () {
        // JavaScript to be fired on all pages

        /**
         * A basic function to scroll to a specific element
         * @param elm
         * @param duration The duration for the scroll animation to execute
         */
        const scrollTo = (elm, duration) => {
            elm.preventDefault()
            const scrollToElm = elm.currentTarget.getAttribute('href')

            if (scrollToElm.length && document.querySelector(scrollToElm) !== null) {
                $('html, body').animate({scrollTop: $(scrollToElm).offset().top}, parseInt(duration))
            }
        }

        /* HAMBURGER MENU */
        const hamburger_menu_button = document.getElementsByClassName('hamburger')
        hamburger_menu_button[0].addEventListener('click', function () {
            this.classList.toggle('active')
            document.getElementsByClassName('topnav')[0].classList.toggle('show')
            document.getElementsByTagName('body')[0].classList.toggle('no-scroll')
        })

        window.addEventListener('resize', function () {
            if (window.innerWidth > 993) {
                document.getElementsByClassName('hamburger')[0].classList.remove('active')
                document.getElementsByClassName('topnav')[0].classList.remove('show')
                document.getElementsByTagName('body')[0].classList.remove('no-scroll')
            }
        })

        $('.topnav .submenu-button').on('click', function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open').next().removeClass('active')
            } else {
                $(this).addClass('open').next().addClass('active')
            }
            return false
        })

        $(".accordion-title").on('click', function () {
            var $this = $(this);
            /*if (!$this.hasClass("active")) {
                $(".accordion-content").slideUp(800);
                $(".accordion-title").removeClass("active");
            }*/
            $this.parent().toggleClass("active");
            $this.next().slideToggle();
            return false
        });

        if ($('.mediaSwiper').length) {
            var mediaSwiper = new Swiper('.mediaSwiper', {
                grabCursor: true,
                slidesPerView: 2,
                navigation: {
                    nextEl: '.media-button-next',
                    prevEl: '.media-button-prev',
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1.6,
                    },
                    375: {
                        slidesPerView: 1.6,
                    },
                    620: {
                        slidesPerView: 1.6,
                    },
                    621: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                },
            })
        }

        if ($('.gallerySwiper').length) {
            var mediaSwiper = new Swiper('.gallerySwiper', {
                spaceBetween: 0,
                grabCursor: true,
                slidesPerView: 3,
                navigation: {
                    nextEl: '.gallery-button-next',
                    prevEl: '.gallery-button-prev',
                },
                breakpoints: {
                    320: {
                        slidesPerView: 1.6,
                    },
                    375: {
                        slidesPerView: 1.6,
                    },
                    620: {
                        slidesPerView: 1.6,
                    },
                    621: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
            })
        }

        const gallery_sliders1 = document.getElementsByClassName('galleryMediumSwiper')
        if (gallery_sliders1 !== null) {
            for (let cs_index = 0; cs_index < gallery_sliders1.length; cs_index++) {
                let gallerySwiper = new Swiper(gallery_sliders1[cs_index], {
                    spaceBetween: 20,
                    grabCursor: true,
                    slidesPerView: 3,
                    pagination: {
                        el: '.gallery-pagination',
                        clickable: true,
                    },
                    breakpoints: {
                        320: {
                            spaceBetween: 0,
                            slidesPerView: 1,
                        },
                        375: {
                            spaceBetween: 0,
                            slidesPerView: 1,
                        },
                        376: {
                            spaceBetween: 0,
                            slidesPerView: 1,
                        },
                        620: {
                            spaceBetween: 20,
                            slidesPerView: 2,
                        },
                        621: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        },
                        1024: {
                            spaceBetween: 20,
                            slidesPerView: 3,
                        },
                    },
                })
            }
        }

        const newSwiper = new Swiper("#hero .swiper-container", {});

        var swiperN = new Swiper(".mySwiper", {
            loop: false,
            spaceBetween: 0,
            slidesPerView: 1,
            freeMode: true,
            watchSlidesProgress: true,
        });
        var swiper2 = new Swiper(".mySwiper2", {
            loop: false,
            spaceBetween: 0,
            thumbs: {
                swiper: swiperN,
            },
        });


        // Scroll To
        (() => {
            $('.scrollto').on('click', elm => scrollTo(elm, 1000))
        })()

        easydropdown.all()

        if ($('.js-filter').length) {
            $('.filter a').on('click', function () {
                $(this).parents('.js-filter').find('.filter__section').toggleClass('show')

                return false
            })
        }

        const content_sliders1 = document.getElementsByClassName('infoSwiper')
        if (content_sliders1 !== null) {
            for (let cs_index = 0; cs_index < content_sliders1.length; cs_index++) {
                let swiper = new Swiper(content_sliders1[cs_index], {
                    autoHeight: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                })
            }
        }

        function equalheight () {
            if ($('.information-section').length) {
                var maxHeight = 0
                $('.information-section .row .col-2 h3').each(function (index) {
                    $(this).height('auto')
                    $(this).each(function (index) {
                        if ($(this).height() > maxHeight)
                            maxHeight = $(this).height()
                    })
                })
                $('.information-section .row .col-2 h3').height(maxHeight)
            }
        }

        equalheight();

        function equalheightIndustry () {
            if ($('.list-industries-section').length) {
                var maxHeight = 0
                $('.list-industries-section .col-3 .row__text').each(function (index) {
                    $(this).height('auto')
                    $(this).each(function (index) {
                        if ($(this).height() > maxHeight)
                            maxHeight = $(this).height()
                    })
                })
                $('.list-industries-section .col-3 .row__text').height(maxHeight)
            }
        }

        function equalheightSolution () {
            if ($('.solutions-box').length) {
                var maxHeight = 0
                $('.solutions-box .solution-item h3').each(function (index) {
                    $(this).height('auto')
                    $(this).each(function (index) {
                        if ($(this).height() > maxHeight)
                            maxHeight = $(this).height()
                    })
                })
                $('.solutions-box .solution-item h3').height(maxHeight)
            }
        }

        equalheightIndustry();
        equalheightSolution();

        $(window).bind('resize', equalheightIndustry);
        $(window).bind('resize', equalheight);
        $(window).bind('resize', equalheightSolution);

        if ($('#item-slide').length) {
            function heightImageBlock()
            {
                $('#item-slide .row__text').removeAttr('style');
                if ($(window).width() > 620) {
                    var heightText = $('#item-slide .row__text').height();
                    $('#item-slide .row__image').height(heightText);
                }
            }

            heightImageBlock();

            $(window).bind('resize', heightImageBlock);
        }

    },
    finalize () {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
}
