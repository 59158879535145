
export default {
    init() {
        let form = $('#form-filter');
        let loadForm = () => {
            $.ajax({
                url: sage.ajax_url,
                data: form.serialize(),
                type: "POST",
                dataType: 'json',
                success: function(data) {
                    $("#all-blog-post").html(data.content);
                    $('#count-posts strong').text(data.count);
                },
            });
        }
        loadForm();

        $('body').on('submit', form, function () {
            $('#form-filter #page-load').val(1);
            $('.filter__section').removeClass('show');
            loadForm();
            $('html, body').animate({scrollTop: $('#to-scroll-blog').offset().top}, 400);
            return false;
        });

        var countChecked = function() {
            var n = $( "input:checked" ).length;
            $('.filter__content .checkbox').addClass('disabled');
            $( "#count-filter-action span" ).text(n);
            $.ajax({
                url: sage.ajax_url,
                data: form.serialize() + '&count-filter=start',
                dataType: 'json',
                type: "POST",
                success: function(data) {
                    if (data.cat) {
                        $.each(data.cat , function(index, val) {
                            $('.filter__content .checkbox[data-name="'+val+'"]').removeClass('disabled');
                        });
                        $('#count-posts strong').text(data.count);
                    } else {
                        $('#count-posts strong').text(0)
                    }

                },
            });
        };
        //countChecked();

        $( "input[type=checkbox]" ).on( "click", countChecked );

        $('body').on('click', '#click-to-load-more-posts', function () {
            var valPage = $(this).attr('data');
            if (valPage) {
                $('#form-filter #page-load').val(valPage);
            }
            $('#button-block-more').remove();
            $.ajax({
                url: sage.ajax_url,
                data: form.serialize(),
                dataType: 'json',
                type: "POST",
                success: function(data) {
                    $("#all-blog-post").append(data.content);
                    $('#count-posts strong').text(data.count);
                },
            });

            return false;
        });

        $('.close-filter').on('click', function(){
            $('input:checkbox').each(function () {
                $(this).prop('checked', false);
            });
            countChecked();
            $('#form-filter #page-load').val(1);
            $('.filter__section').removeClass('show');
            loadForm();
            return false;
        });



    },
    finalize() {

    },
};
